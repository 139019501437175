import React from 'react'
import './Python.css'
function Learnings(props) {
  return (
    <div className='learning-box'>
        <h5>{props.text}</h5>
    </div>
  )
}

export default Learnings