import React from 'react'

function Pyform() {
  return (
    <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScpjRC6lfkTfLvzlciJAQ6j3li0RyyG_2t6uwwQiLQzK4XRsA/viewform?embedded=true" width="100%" height="800" title="python form" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default Pyform