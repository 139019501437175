import React from 'react'

function Resumeform() {
  return (
    <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfJPIUsJy75zb5sSKOjHrE8OruZAgUbfEgcwXni6Og-mIZhBQ/viewform?embedded=true" width="100%" height="789" frameborder="0" marginheight="0" title="v" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default Resumeform